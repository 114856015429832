import React from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/layout';
import Container from '../components/container';

export const query = graphql`
	query SITE_INDEX_QUERY {
		allMdx(
			sort: { fields: [frontmatter___date], order: DESC }
			filter: { frontmatter: { published: { eq: true } } }
		) {
			nodes {
				id
				excerpt(pruneLength: 120)
				timeToRead
				frontmatter {
					title
					date(formatString: "Do MMM YYYY")
					featuredImage {
						childImageSharp {
							fluid(traceSVG: { color: "#7269D1" }) {
								...GatsbyImageSharpFluid_tracedSVG
							}
						}
					}
				}
				fields {
					slug
				}
			}
		}
	}
`;
const CalendarIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    className="h-4 w-4 inline mr-2 mb-1 align-middle"
		>
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
    />
  </svg>
);

const ClockIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    className="h-4 w-4 inline mr-1.5 mb-1 align-middle"
		>
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
);

const Blog = ({ data }) => (
  <Layout>
    {/* <div className="dot-background py-36 text-center text-gray-800">
        <h1 className="text-6xl mb-12">My Journal</h1>
        <p className=" max-w-lg mx-auto text-lg">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates
          esse quisquam ea distinctio rerum dolore omnis enim dolor veniam,
          nobis iste harum, veritatis perspiciatis. Ipsa.
        </p>
      </div> */}
    <div className="dark:bg-cloud-700 bg-cloud-50 pb-12">
      <Container className="pt-24  text-gray-600 dark:text-gray-300 ">
        <div className="mb-20">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 md:grid-cols-2 ">
            {data.allMdx.nodes.map(
							  ({
							    excerpt,
							    frontmatter,
							    fields,
							    timeToRead,
							  }) => (
  <>
    {/* Blog card  */}
    <Link to={fields.slug}>
      <div className="dark:bg-cloud-500 bg-white shadow p-8  rounded-lg group hover:shadow-xl duration-500 transform transition ">
        <div>
          {frontmatter.featuredImage && (
          <Img
            fluid={
																frontmatter
																  .featuredImage
																  .childImageSharp
																  .fluid
															}
            className="mb-6 rounded-lg"
          />
          )}
          <h1 className="text-2xl mb-2 font-semibold text-gray-900 dark:text-gray-50 ">
            {frontmatter.title}
          </h1>
          <div className="mb-4">
            {excerpt}
          </div>
          <p className=" text-sm text-gray-400">
            <CalendarIcon />
            {frontmatter.date}
            {' '}
            {' '}
            <ClockIcon />
            {timeToRead}
            {' '}
            min read
          </p>
        </div>
      </div>
    </Link>
  </>
							  ),
            )}
          </div>
        </div>
      </Container>
    </div>
  </Layout>
);

export default Blog;
